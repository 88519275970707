<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          src="../../../../public/waltcarbon.png"
          class="logo-image"
          height="22px"
          alt="logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="login-details d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold text-white" title-tag="h2">
            Welcome to
            <b-img
              :src="darkImg"
              alt="logo"
              height="17px"
              width="140px"
              class="title-style"
            />
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group>
                <label class="label-color" for="login-email">Email</label
                ><validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    tabIndex="1"
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    class="input-field"
                  />
                  <small class="text-white">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="label-color" for="login-password"
                    >Password</label
                  >
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small class="text-white">Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      tabIndex="2"
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge input-field"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text class="eye-icon">
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-white">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                  class="remember-me-checkbox"
                >
                  <span class="text-white"> Remember Me</span>
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                class="color-black-background"
                block
                :disabled="invalid"
              >
                <b-spinner v-if="isLoading" label="Text Centered"></b-spinner>
                <span v-if="!isLoading">Sign in</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BSpinner,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/waltcarbon_login.png"),
      darkImg: require("@/assets/images/logo/waltcarbon.png"),
      togglePassword: false,
      isLoading: false,
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/waltcarbon_login.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.isLoading = true;
      this.$refs.loginForm.validate().then((success) => {
        const loginData = JSON.stringify({
          email: this.userEmail,
          password: this.password,
        });
        const customConfig = {
          headers: { "Content-Type": "application/json" },
        };

        axios
          .post("/auth/login", loginData, customConfig)
          .then((response) => {
            this.isLoading = false;
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response.data.idToken)
            );
            localStorage.setItem(
              "refreshToken",
              JSON.stringify(response.data.refreshToken)
            );

            const userData = {
              company_address_lines1: response.data.company_address_lines1,
              company_address_lines2: response.data.company_address_lines2,
              company_address_lines3: response.data.company_address_lines3,
              company_email: response.data.company_email,
              company_id: response.data.company_id,
              company_name: response.data.company_name,
              company_website: response.data.company_website,
              disabled: response.data.disabled,
              display_name: response.data.display_name,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              // gcp_billing_account_id: response.data.gcp_billing_account_id,
              ability: [{ action: "manage", subject: "all" }],
              avatar: "/img/1-small.d796bffd.png",
              email: response.data.email,
              fullName: response.data.display_name,
              accountId: response.data.accountId,
              id: response.data.uid,
              cloud_customer_id: response.data.cloud_customer_id,
              role_id: response.data.role_id,
              role_name: response.data.role_name,
              account_manager: response.data.account_manager_details,
              region_id: "",
              default_region_id: response.data.regions_details[0].id,
              currency_code: response.data.currency_code,
              deafult_currency_code:
                response.data.regions_details[0].currencies[0].code,
            };

            if (userData.role_name === "GLOBAL_ADMIN") {
              userData.region_id = "0afb05a8-da40-41d9-9e51-e288e6db7789";
            } else {
              userData.region_id = response.data.region_id;
            }

            const regionCurrencyData = {
              regions_details: response.data.regions_details,
            };

            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem(
              "regionCurrencyData",
              JSON.stringify(regionCurrencyData)
            );
            store.commit("app/USER_DATA", userData);
            store.commit(
              "app/CURRENCY_TYPE",
              response.data.regions_details[0].currencies[0].code
            );

            this.$ability.update(userData.ability);
            this.$router
              .replace(getHomeRouteForLoggedInUser(userData.role_name))
              .then(() => {
                this.isLoading = false;
              });
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: `Login Failed`,
              },
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
@import "~@core/scss/base/bootstrap-extended/variables";

.spinner-border {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: text-bottom;
}
.label-color {
  color: $white;
}
.logo-image {
  margin-top: 5px;
  margin-left: 1rem;
}
.color-black-background {
  background-color: $black !important;
  color: $white !important;
}
.color-black-background:disabled {
  background-color: $black !important;
  opacity: 1;
}
.dark-layout .btn-secondary {
  background-color: none !important;
  border: none !important;
}
.input-field {
  border: $custom-control-border-color solid 1px !important;
  background: $white !important;
  color: $body-color !important;
}
.eye-icon {
  .input-group-text {
    background-color: $white !important;
    border: $custom-control-border-color solid 1px !important;
    color: $body-color !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
}
.title-style {
  margin-bottom: 0.3rem;
}
</style>
